import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ArchivesService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  public selectedThematic: any;

  
  getAllThematicSheets(){
    return this.http.get<any>(this.authService.endpoint + "/thematics/allSheets").toPromise();
  }

  getThematicSheetThemes(tSheetId: number) {
    return this.http.get<any>(this.authService.endpoint + `/thematics/themes/${tSheetId}`).toPromise();
  }

  getThematicSheetSponsors(tSheetId: number) {
    return this.http.get<any>(this.authService.endpoint + `/thematics/sponsors/${tSheetId}`).toPromise();
  }

  updateThematicSheet(sheet: object, sheetId: number) {
    return this.http.post<any>(this.authService.endpoint + `/thematics/update/${sheetId}`, sheet).toPromise();
  }

  //Deletes all ssponsor mapping existing with the thematic sheet id
  deleteThematicSheetSponsors(tSheetId: number) {
    return this.http.delete<any>(this.authService.endpoint + `/thematics/sponsors/delete/${tSheetId}`).toPromise();
  }

  //Delete all themes of the thematic sheet = all the theme mapping existing with the thematis sheet id
  deleteThematicSheetThemes(tSheetId: number) {
    return this.http.delete<any>(this.authService.endpoint + `/thematics/themes/delete/${tSheetId}`).toPromise();
  }

  public addThematic(thematic:object): Promise<any> {
    
    return this.http.post<any>(this.authService.endpoint + "/thematics/new", thematic).toPromise();
  }

  public addThematicSponsors(thematicSponsors:object): Promise<any> {
    
    return this.http.post<any>(this.authService.endpoint + "/thematics/newSponsor", thematicSponsors).toPromise();
  }

  public addThematicTheme(thematicTheme:object): Promise<any> {
    
    return this.http.post<any>(this.authService.endpoint + "/thematics/newTheme", thematicTheme).toPromise();
  }

}

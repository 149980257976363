import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Survey } from 'src/app/model/survey.model';
import { Inquiry } from 'src/app/model/inquiry.model';
import { Door } from 'src/app/model/door.model';
import { API } from "aws-amplify";
import { doorDialogComponent } from 'src/app/sesame/dtod/dtod.component';
import { AuthService } from 'src/app/sesame/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

   url = "http://localhost:3000";
   private apiName = "isoprodAPI";
  constructor(
   private http : HttpClient,
   private authService: AuthService
  ) { }

  getSurveysDataOfInquiry(inquiryId:number)  {
    const req = {
     inquiryId: inquiryId
    }
    return this.http.post<any>(this.authService.endpoint + '/getSurvey/inquiry',req).toPromise();
  }
assignUserToSurvey(userAppId:number,doorId:number,inquiryId:number)  {
  const req = {
    userAppId: userAppId,
    doorId: doorId,
    messageId: inquiryId
  }
  return this.http.post<any>(this.authService.endpoint + '/survey/user',req).toPromise();
}

  deleteSurveyById(id:number)  {
    const req = {
      
        id:id
      
    }
    return this.http.post<any>(this.authService.endpoint + '/survey/delete',req).toPromise();
  }

  updateSurvey(idDoor: number,statut: string, Editable: number)  {
    const req = {
      "idDoor": idDoor, "status": statut, "isEditable": Editable
    }
    return this.http.post<any>(this.authService.endpoint + '/updateSurvey',req).toPromise();
  }

  createSurvey(survey: Survey)  {
    const req = {
      body: survey
    }
    return this.http.post<any>(this.authService.endpoint + "/surveys", survey).toPromise();
  }

  getDoorsOfSurveyByInquiry(inquiry: Inquiry) {
    const req = {
      body: inquiry
    }
    return this.http.post<any>(this.authService.endpoint + '/doorsOfSurvey/inquiry/doors',inquiry).toPromise();
  }

  getDistrictDataByInquiryId(inquiryId: number) {
      const request = {
        inquiryId
      }
      return this.http.post<any>(this.authService.endpoint +  '/doorsOfSurvey/inquiry/data', request).toPromise();
  }

  reAssignUserToSurvey(userAppId:number, surveyId:number){
    const body = {
    
      userAppId: userAppId,
      surveyId: surveyId
    
    }
    return this.http.post<any>(this.authService.endpoint +  '/survey/user/update', body).toPromise();
  }
}
